<template>
  <div>
          <default-app-bar @click="" > 
         <template v-slot:title>
          <div class="text-h3 mr-2"> Estudio de Crédito </div>
         </template>
    
         
         <template v-slot:actions>
              <router-link  to="/admin/solicitudes/list-desembolsado"   color="primary" class="mr-2" >
                <!--v-icon small>mdi-file-pdf </v-icon--> 
                Volver
              </router-link>
              <a  small   color="primary" class="btn-margin0" elevation="1"  v-on:click="print">
                <v-icon small>mdi-file-pdf </v-icon> 
              </a>
         
         
         </template>
       </default-app-bar>

  <div v-html="body" class="pa-2" id="estudio-credito">
     

  </div>
</div>

</template>

<script>

  export default {
    name: 'Desembolso',
    data () {
      return {
        body: ''
      }
    },
    mounted(){

    const url = 'https://reportes.serprogreso.com/' + 'report/html/plan-pagos/' + this.$route.params.id;
      this.$http.getUrl(url).then((response) => {
        console.log(response)
        this.body = response;
      })
    },
    props: ['info'],
    methods: {
      print(){
        const w = window.open();
        var printContents = document.getElementById("estudio-credito").innerHTML;
        w.document.write(printContents);
        w.print();
w.close();
      }
    }
  }
</script>
<style>
table{
  width: 100%;
  border-spacing: 0px;
  border: 1px solid #eee;
}
table td{
  padding: 2px !important;
}
</style>